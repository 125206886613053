.loans-everyone {

    .loans-everyone-header {
        width: 100%;
        max-width: 830px;
        margin: auto auto 80px;

        h2 {
            margin-bottom: 0;
        }

        .subtitle {
            margin-top: 30px;
        }
    }

    .loans-everyone-list {
        margin-top: 80px;
        margin-bottom: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 50px 0;

        @media (min-width: 992px) {
            gap: 80px 10px;
        }

        @media (min-width: 1200px) {
            gap: 80px 20px;
        }

        li {
            list-style-type: none;
            width: 100%;
            
            @media (min-width: 680px) {
                max-width: 50%;
            }

            @media (min-width: 992px) {
                max-width: calc(100% / 3 - 10px);
            }

            @media (min-width: 1200px) {
                max-width: calc(100% / 3 - 20px);
            }
        }

        .loans-everyone-img {
            max-width: 280px;
            width: 100%;
            height: 160px;
            position: relative;
            border-radius: 15px;
            background: #FFF;
            margin: auto;

            img {
                margin: auto;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        .loans-everyone-text {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 18px;

            span {
                color: #970010;
            }

            @media (min-width: 1200px) {
                font-size: 20px;
            }
        }
    }

    .loans-everyone-btn {
        color: #FFF;
        text-decoration: none;
        text-align: center;
        font-size: 20px;
        width: 232px;
        height: 64px;
        line-height: 64px;
        border-radius: 10px;
        background: #970010;
        display: block;
        margin: 10px auto 20px;

        &:hover {
            background: #db3544;
            box-shadow: 0 4px 10px 0 rgba(0,26,24,.15), 0 4px 5px 0 rgba(0,26,24,.05);
        }
    }
}