#reasons {
    .reason-subhead {
        display: flex;
        justify-content: center;
        margin: 80px auto;

        h3 {
            text-align: left;
            display: inline-block;
            font-size: 30px;
            font-weight: 600;

            span {
                color: #970010;
            }
        }
    }

    .reasons-img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;

        @media (min-width: 1100px) {
            position: absolute;
            z-index: 100;
            margin: auto auto 220px;
            left: -600px;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: relative;
        overflow: hidden;

        li {
            height: 95px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: end;
            background-color: #fff;

            &:nth-child(even) {
                background-color: transparent;
            }

            .container {
                display: flex;

                img {
                    display: none;

                    @media (min-width: 1100px) {
                        display: block;
                        position: absolute;
                        top: 100px;
                    }

                }

                .reason-text {
                    margin: 10px auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 570px;
                    height: 60px;

                    border-left: 1px solid #970010;

                    @media (min-width: 1100px) {
                        margin: 10px 0 10px auto;
                    }

                    &::before {
                        content: "";
                        display: block;
                        width: 18px;
                        height: 20px;
                        background: url("../img/sprite.svg#sprite--arrow-li-right") no-repeat;
                        position: relative;
                        left: 10px;
                        margin-right: 15px;
                        padding-right: 15px;
                        padding-left: 15px;
                    }

                    p {
                        text-align: right;
                        font-size: 18px;
                        line-height: 1;
                        margin: 0;

                        @media (min-width: 992px) {
                            font-size: 24px;
                        }
                        
                        span {
                            color: #970010;
                            display: inline;
                        }
                    }
                }
            }
        }
    }

    .reason-registration {
        max-width: 280px;
        width: 100%;
        height: 70px;
        border-radius: 10px;
        background: #DB3544;
        margin: 70px auto 120px;
        color: #FFF;

        text-align: center;
        line-height: 70px;
        font-size: 20px;
        display: block;
        text-decoration: none;

        @media (min-width: 768px) {
            max-width: 460px;
            font-size: 30px;
            height: 100px;
            line-height: 100px;
        }

        &:hover {
            background-color: #970010;
            box-shadow: 0 4px 10px 0 rgba(0,26,24,.15), 0 4px 5px 0 rgba(0,26,24,.05);
        }
    }
}
