.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    width: 100%;
    margin-top: 16px;

    @media (min-width: 992px) {
        height: 25px;
        height: 66px;
    }

    .account {
        position: relative;
        display: block;
        align-self: flex-start;
        margin-top: 10px;

        /*align-self: baseline;
         margin-bottom: 25px; */
        @media (min-width: 992px) {
            margin-top: 0;
            margin-bottom: 25px;
            align-self: auto;
        }

        a {
            margin-right: 0;
            display: flex;
            align-items: end;
            justify-content: flex-end;
            text-decoration: none;
            text-align: center;
            text-transform: lowercase;

            @media (min-width: 992px) {
                margin-right: 20px;
            }

            &::after {
                content: "";
                display: inline-block;
                width: 8px;
                height: 8px;
                background: url("../img/sprite.svg#sprite--arrow-li-down") no-repeat;
                position: absolute;
                margin-right: -3px;
                top: calc(50% - 4px);

                @media (min-width: 992px) {
                    top: calc(50% - 7px);
                    width: 15px;
                    height: 15px;
                    margin-right: -18px;
                }
            }

            span {
                display: inline-block;
                color: #000;
                text-align: center;
                text-transform: lowercase;
                font-size: 12px;
                width: 107px;
                display: inline-block;

                @media (min-width: 992px) {
                    font-size: 16px;
                    width: auto;
                }

                &::first-letter {
                    color: #970010;
                }
            }
        }
    }

    .navbar__list {
        list-style-type: none;


        display: none;

        @media (min-width: 992px) {

            height: 25px;
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0 auto 25px;
        }

        &.menu__list--open {
            @media (max-width: 992px) {
                display: block;
                position: absolute;
                top: 80px;
                background: rgba(255, 251, 246, .9);
                width: 100%;
                left: 0;
                right: 0;
                height: auto;
            }

            li {
                @media (max-width: 992px) {
                    padding: 0 15px;
                }

                a {
                    @media (max-width: 992px) {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .navbar__list__item {
        margin: 0;
        position: relative;
        padding: 0 8px;

        a {
            text-align: center;
            line-height: 1.5;
            color: #000;
            text-decoration: none;
            text-transform: lowercase;

            &:hover {
                opacity: 0.5;
            }

            &::first-letter {
                color: #970010;
            }
        }

        &.active {
            a {
                font-weight: 600;
            }

            &::before {
                @media (min-width: 992px) {
                    content: '';
                    width: 16px;
                    height: 20px;
                    display: block;
                    background-color: #F5F5F5;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: 0 auto;
                }
            }
        }
    }

    @media (min-width: 992px) {

        .navbar__list__item {
            display: flex;
            height: 25px;
            align-items: center;
            border-right: 1px solid #000;

            &:last-child {
                border-right: none;
            }
        }

        /* .navbar__list__item:not(:last-child) {
            margin-right: 14px;
        } */
    }

    .navbar-nav {
        margin: auto;
    }

    .navbar-toggler {
        display: none;
    }
}