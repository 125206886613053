.map {
    width: 100%;
    max-width: 100%;
    height: 210px;

    @media (min-width: 768px) {
        height: 500px;
    }

    @media (min-width: 992px) {
        height: 600px;
    }

    @media (min-width: 1200px) {
        height: 924px;
    }
}