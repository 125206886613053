header {
    display: flex;
    flex-direction: column;
    align-items: end;

    position: relative;
    z-index: 1000;

    &.fixed {
        position: fixed;
        background-color: #fff;
        box-shadow: 0 4px 10px 0 rgba(0, 26, 24, 0.15), 0px 4px 5px 0px rgba(0, 26, 24, 0.05);

        @media (min-width: 992px) {
            background-color: transparent;
            box-shadow: none;
            position: relative;
        }

        .navbar {
            margin-top: 5px;
        }

        .account,
        .social-icons {
            display: none;
        }

        .navbar-toggler {
            background: transparent;
            border: 0;
            width: 100px;
            height: 45px;
            display: block;
            box-shadow: none;
            margin-left: 5px;
            cursor: pointer;
            order: 1;
            width: 100px;

            @media (min-width: 992px) {
                display: none;
                order: unset;
                margin: 0;
            }

            .navbar-toggler-icon {
                width: 40px;
                height: 40px;
                margin: auto 0 auto auto;
                background-color: rgba(151, 0, 16, 0.5);
                border-radius: 50%;
            }
        }
    }

    .header {
        grid-area: 1/2/2/3;
        height: 70px;
        position: relative;
        z-index: 1000;
        /* width: 100%; */

        background-color: transparent;
        /*box-shadow: 0 4px 10px 0 rgba(0, 26, 24, 0.15), 0px 4px 5px 0px rgba(0, 26, 24, 0.05);*/
        position: relative;

        @media (min-width: 992px) {
            display: flex;
            align-items: center;
            margin-top: 30px;
            padding: 0 55px;
        }

        .logo {
            text-decoration: none;
            text-align: center;
            display: block;
            overflow: hidden;
            width: 100%;
            max-width: 149px;
            height: auto;

            picture {

                margin: 0;
                padding: 0;
                width: 100%;
                height: auto;

                img {
                    margin-top: 12px;
                    width: 100%;
                    height: auto;

                    @media (min-width: 992px) {
                        margin: 0;
                    }
                }
            }
        }

        .header-logo {
            display: flex;
            vertical-align: middle;
            align-items: center;
            width: 50px;
            height: 50px;
            text-decoration: none;
            margin: 0;
            margin-right: 10px;
            outline: none;
            border: none;
            text-decoration: none;

            @media (min-width: 1200px) {
                width: 200px;
            }

            &:hover,
            &:active,
            &:focus,
            &:visited,
            &:focus-within,
            &:focus-visible {
                color: #000;
                outline: none;
                border: none;
                text-decoration: none;
            }
        }
    }

    .social-icons {
        display: flex;
        justify-content: end;
        margin-right: 0;
    }

    .nav-soc {
        position: relative;
        top: -22px;

        @media (min-width: 992px) {
            top: 0;
            padding-right: 55px;
        }

        .social-icons {
            ul {
                gap: 5px;

                @media (min-width: 992px) {
                    gap: 10px;
                }

                li {
                    width: 20px;
                    height: 20px;
                    display: block;
                    overflow: hidden;
                    margin: 0;
                    padding: 0;

                    @media (min-width: 992px) {
                        width: 25px;
                        height: 25px;
                    }

                    a {
                        width: 20px;
                        height: 20px;
                        display: block;
                        overflow: hidden;
                        margin: 0;
                        padding: 0;

                        @media (min-width: 992px) {
                            width: 25px;
                            height: 25px;
                        }

                        img {
                            width: 100%;
                            height: 100%;

                            margin: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}