body {
  padding: 0;
  margin: 0;
  font-family: 'Exo', sans-serif;
  font-style: normal;
  line-height: 1.3;
  font-size: 16px;
  font-weight: 400;
  color: #000;

  box-sizing: border-box;

  background-color: #EFF2F7;
  background-image: url('../img/bg.png');
  background-image: image-set(
        url("../img/bg.webp") type("image/webp"),
        url("../img/bg.jpg") type("image/jpeg")
    );
  background-repeat: repeat-y;

  &.lazy {
    background-image: none;
  }
}

.container,
.container-fluid {
  width: 100%;
  margin: 0;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;

  @media (min-width: 520px) {
    padding-left: 55px;
    padding-right: 55px;
  }

  @media (min-width: 1260px) {
    width: 1200px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.container-fluid {
  padding: 0;
  width: 100%;
}


main {
  position: relative;
  top: -136px;
  /* margin-top: -136px; */
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: block;
  margin-bottom: -136px;
}

[hidden],
.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.row {
  display: flex;
  flex-direction: column;
  margin: 0 28px;

  @media (min-width:992px) {
    flex-direction: row;
  }
}

.main-content {
  width: 100%;
}

.page {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (min-width: 768px) {
    margin-bottom: 25px;
  }

  h1 {
    color: #000;
    font-family: "IBM Plex Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    display: flex;
    align-items: center;

    @media (min-width: 480px) {
      font-size: 18px;
    }

    &::before {
      content: "";
      width: 24px;
      height: 16px;
      display: block;
      background: #005853;
      position: absolute;
      left: 0;
    }

    @media (min-width: 768px) {
      display: none;
    }
  }

  ul {
    margin: auto 0;

    @media (min-width: 768px) {
      margin: auto 0 auto auto;
    }

    li {
      display: inline;
      margin-right: 5px;

      a {
        color: #ABABAB;
        font-family: 'Exo', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: none;

        @media (min-width: 480px) {
          font-size: 18px;
        }

        &.active {
          color: #000;
          border-bottom: 2px solid #005853;
        }
      }
    }
  }
}

h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;

  span {
      color: #970010;
  }

  @media (min-width: 768px) {
      font-size: 40px;
  }
}

p.subtitle {
  font-size: 20px;
  font-weight: 600;

  span {
      color: #970010;
  }

  @media (min-width: 768px) {
      font-size: 30px;
  }
}

button,
input[type=submit] {
  cursor: pointer;
}

picture {
  display: block;
  overflow: hidden;
}

.slick-list {
  width: 280px;

  @media (min-width:768px) {
    width: 736px;
  }

  @media (min-width:992px) {
    width: 960px;
  }

  .slick-track {
    margin-bottom: 0;
    gap: 0;

    @media (min-width:768px) {
      gap: 60px;
    }
  }
}

.slick-next,
.slick-prev {
  border: none;
  outline: none;
  position: absolute;
  top: calc(50% - 60px);
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 10;

  &:hover {
    opacity: 0.5;
  }

  @media (min-width: 768px) {
    width: 40px;
    height: 40px;
  }
}

.slick-prev {
  background: url("../img/sprite.svg#sprite--slick-prev");
  left: calc(50% - 175px);
  @media (min-width: 768px) {
    left: 0;
  }
}

.slick-next {
  background: url("../img/sprite.svg#sprite--slick-next");
  right: calc(50% - 175px);
  @media (min-width: 768px) {
    right: 0;
  }
}