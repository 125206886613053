.reviews {
    margin-bottom: 120px;
    
    .reviews-header {
        display: block;
        margin: auto;
        width: 100%;
        max-width: 820px;

        .subtitle {
            width: 100%;
            max-width: 824px;
            display: block;
            margin-bottom: 100px;
        }
    }

    .reviews-wrap {
        display: flex;
        justify-content: center;
        align-items: stretch;
        gap: 60px;
        width: 100%;

        .slick-track {
            margin-bottom: 60px;
        }
        
        .reviews-item {
            width: 280px !important;
            min-height: 240px;
            height: auto;

            border-radius: 15px;
            background: #FFF;
            position: relative;

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: #970010;
                position: absolute;
                bottom: 15px;
            }

            p {
                font-size: 12px;
                text-align: center;
                margin: 30px 20px 100px;
                display: block;
            }

            .reviews-photo {
                border-radius: 50%;
                background: #970010;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 120px;
                height: 120px;
                margin: auto;
                position: absolute;
                bottom: -60px;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 2;

                span {
                    position: absolute;
                    top: -20px;
                    color: #970010;
                    font-size: 12px;
                }

                img {
                    border-radius: 50%;
                }
            }
        } 
    }
}