.request {
    padding: 0 15px;
    .request-header {
        display: block;
        margin: 92px auto auto;
        width: 100%;
        max-width: 965px;

        h2 {
            width: 100%;
            max-width: 300px;

            @media (min-width: 570px) {
                max-width: 100%;
            }
        }

        .subtitle {
            margin-bottom: 0;

            width: 100%;
            max-width: 300px;

            margin-top: 30px;
            margin-bottom: 20px;

            @media (min-width: 570px) {
                max-width: 100%;
            }
        }
    }

    img {
        display: block;
        margin: auto;

        @media (min-width: 1100px) {
            display: none;
        }
    }

    .request-forms-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        margin: 0;

        @media (min-width: 550px) {
            flex-direction: row;
            justify-content: space-between;
            margin: 54px auto 70px;
        }

        .request-menu-wrap {
            display: flex;
            margin: 0 auto;
            width: 100%;

            @media (min-width: 550px) {
                width: 50%;
            }

            @media (min-width: 1100px) {
                display: flex;
            }

            .request-form-type {
                display: block;
                font-size: 20px;
                font-weight: 600;
                color: #000;
                text-decoration: none;
                position: relative;
                margin-bottom: 15px;
                text-align: left;
                padding: 0;
                width: 100%;
                max-width: 440px;
                cursor: pointer;

                @media (min-width: 550px) {
                    margin-bottom: 0;
                    font-size: 30px;
                    text-align: center;
                    padding: 0;
                }

                span {
                    color: #970010;
                }

                &::after {
                    content: "";
                    display: inline-block;
                    width: 20px;
                    height: 18px;
                    background: url(../img/sprite.svg#sprite--arrow-li-down) no-repeat;
                    margin-left: 5px;
                    position: absolute;
                    top: 6px;

                    @media (min-width: 550px) {
                        top: 12px;
                    }
                }
            }
        }

    }

    .request-forms {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin: auto;

        .request-form-wrap {
            width: 100%;
            max-width: 440px;

            display: none;

            &.active {
                display: flex;
                margin: auto;
                flex-direction: column;

                @media (min-width: 1100px) {
                    margin: inherit;
                    flex-direction: row;
                }
            }

            @media (min-width: 1100px) {
                display: flex;
                margin: inherit;
                flex-direction: row;
            }

            &:nth-child(2) {
                position: relative;
            }


            .request-forms-bg {
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;

                display: none;

                @media (min-width: 1100px) {
                    display: block;
                }

                @media (min-width: 1200px) {
                    left: -70px;
                }
            }

            .request-form {
                border-radius: 15px;
                background: #FFF;

                display: flex;
                flex-direction: column;
                padding: 0 25px;

                @media (min-width: 768px) {
                    padding: 0 45px;
                }


                .request-form-head {
                    font-size: 20px;
                    text-align: center;
                    margin: 50px auto;

                    span {
                        color: #970010;
                    }

                    @media (min-width: 768px) {
                        font-size: 24px;
                    }
                }

                .request-form-btn {
                    max-width: 232px;
                    width: 100%;
                    height: 64px;
                    border-radius: 10px;
                    background: #970010;
                    color: #FFF;
                    text-align: center;
                    font-size: 20px;
                    margin: 30px auto 16px;
                    display: block;
                    border: none;
                    cursor: pointer;

                    &:hover {
                        background: #db3544;
                        box-shadow: 0 4px 10px 0 rgba(0, 26, 24, .15), 0 4px 5px 0 rgba(0, 26, 24, .05);
                    }
                }

                .request-form-policy {
                    color: rgba(74, 73, 74, 0.80);
                    text-align: center;
                    font-size: 12px;
                    max-width: 260px;
                    width: 100%;
                    display: block;
                    margin: 0 auto 50px;

                    a {
                        color: rgba(151, 0, 16, 0.80);
                        font-family: Exo;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 130%;
                        text-decoration-line: underline;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}