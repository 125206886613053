.footer {
    background-color: #D1D4D6;
    overflow: hidden;

    min-height: 200px;

    .footer-top {
        display: flex;
        margin-top: 35px;

        flex-direction: column;
        align-items: center;

        @media (min-width: 1100px) {
            flex-direction: row;
        }

        .footer-items {
            display: flex;
            flex-direction: column;

            align-items: center;
            margin: auto;

            @media (min-width: 1100px) {
                flex-direction: row;
                justify-content: space-between;
                margin: 0px auto;
                position: relative;
                top: -25px;
            }

            ul {
                padding: 4px;
                margin: 0;

                li {
                    display: block;
                    margin: 35px auto;
                    position: relative;
                    text-align: center;

                    @media (min-width: 1100px) {
                        display: inline;
                        margin-right: 40px;
                        text-align: left;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    &::after {
                        content: "";
                        display: block;
                        width: 18px;
                        height: 20px;
                        background: url("../img/sprite.svg#sprite--arrow-li-down") no-repeat;
                        position: absolute;
                        left: 0;
                        bottom: -25px;
                        right: 0;
                        margin: auto;

                    }

                    a {
                        text-decoration: none;
                        font-size: 20px;
                        line-height: 1.5;
                        display: inline-block;
                        color: #000;
                        text-transform: lowercase;

                        &:hover {
                            opacity: 0.5;
                        }

                        &::first-letter {
                            color: #970010;
                        }
                    }

                }
            }
        }
    }

    .footer-bottom {
        display: flex;
        margin: 0 auto 26px;

        .policy {
            margin: 25px auto 50px;
            line-height: 1.5;
            color: #000;
            text-decoration: none;

            border-bottom: 1px solid #000;

            @media (min-width: 1100px) {
                margin: auto;
            }
        }
    }
}