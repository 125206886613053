#services {
    margin-top: 100px;

    .services-header {
        display: block;
        margin: auto;
        width: 100%;
        max-width: 750px;

        h2 {
            font-weight: 600;
            width: 100%;

            @media (min-width: 640px) {
                max-width: 65%;
            }

            @media (min-width: 992px) {
                max-width: 740px;
            }
        }

        .subtitle {
            margin-bottom: 20px;
        }
    }

    .services-menu {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: center;
        width: 100%;
        max-width: 750px;
        padding: 0;
        margin: auto;

        @media (min-width: 754px) {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }

        li {
            display: block;
            width: 100%;

            @media (min-width: 754px) {
                width: 50%;
            }

            a {
                display: block;
                font-size: 20px;
                font-weight: 600;
                color: #000;
                text-decoration: none;
                position: relative;
                margin-bottom: 20px;

                @media (min-width: 768px) {
                    margin-bottom: 100px;
                }

                @media (min-width: 992px) {
                    font-size: 30px;
                }

                span {
                    color: #970010;
                }

                &::after {
                    content: "";
                    display: inline-block;
                    width: 15px;
                    height: 13px;
                    background: url(../img/sprite.svg#sprite--arrow-li-down) no-repeat;
                    margin-left: 5px;
                    position: absolute;
                    top: 7px;

                    @media (min-width: 992px) {
                        width: 20px;
                        height: 18px;
                        top: 12px;
                    }
                }
            }
        }
    }



    .services-list {
        display: none;

        &.active {
            display: flex;
        }

        flex-wrap: wrap;
        justify-content: center;
        gap: 40px;

        .services-item {
            max-width: 350px;
            width: 100%;
            background-color: #fff;
            border-radius: 15px;
            margin-top: 0;
            transition: 0.5s;

            &:hover {
                @media (min-width: 768px) {
                    transform: scale(1.1);
                }
            }

            @media (min-width: 768px) {
                margin-top: 50px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            h3 {
                text-align: center;
                font-size: 20px;
                width: 100%;
                display: block;
                margin: 40px auto 16px;
                font-weight: 400;

                @media (min-width: 768px) {
                    width: 310px;
                    font-size: 30px;
                    margin: 50px auto 16px;
                }
            }

            a {
                display: block;
                width: 200px;
                height: 50px;
                border-radius: 10px;
                background: #970010;
                text-decoration: none;
                color: #FFF;
                text-align: center;
                font-size: 18px;
                line-height: 50px;
                margin: 24px auto;

                @media (min-width: 400px) {
                    font-size: 20px;
                    line-height: 64px;
                    height: 64px;
                    width: 232px;
                }

                &:hover {
                    background: #db3544;
                    box-shadow: 0 4px 10px 0 rgba(0, 26, 24, .15), 0 4px 5px 0 rgba(0, 26, 24, .05);
                }
            }

            .services-item-desc {
                width: 100%;
                max-width: 250px;
                height: 50px;
                border-radius: 15px;
                background: #E9FFFA;
                margin: 0 auto;
                color: #07C68F;
                text-align: center;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (min-width: 768px) {
                    max-width: 300px;
                    font-size: 24px;
                }
            }

            .services-item-info {
                height: auto;

                @media (min-width: 768px) {
                    min-height: 194px;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                }
            }

            .services-item-img {
                position: relative;
                display: contents;

                @media (min-width: 768px) {
                    display: flex;
                    height: 100%;
                }

                img {
                    margin: auto;
                    display: block;

                    max-width: 100%;
                    height: auto;

                    @media (min-width: 400px) {
                        width: auto;
                    }
                }
            }
        }

        &.services-list--individuals {
            .services-item {
                @media (min-width: 1240px) {
                    &:first-child {
                        margin-left: 150px;
                    }

                    &:nth-child(2) {
                        margin-right: 150px;
                    }
                }
            }
        }

        &.services-list--business {
            .services-item {
                .services-item-info {
                    height: auto;

                    @media (min-width: 768px) {
                        min-height: 244px;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: space-between;

                    }
                }


                .services-item-img {
                    height: 346px;
                }
            }
        }
    }

    .counter {
        display: flex;
        flex-wrap: wrap;
        gap: 0 100px;
        margin: 50px auto;
        justify-content: center;

        @media (min-width: 768px) {
            margin: 100px auto;
        }

        .counter-item {

            hr {
                border-top: 1px solid #970010;
            }

            .counter-top {
                text-align: center;
                color: #970010;
                font-size: 30px;
                font-weight: 600;
                margin-bottom: 0;

                @media (min-width: 768px) {
                    font-size: 50px;
                }
            }

            .counter-text {
                font-size: 18px;
                text-align: center;
                margin-top: 0;
                max-width: 280px;

                @media (min-width: 768px) {
                    max-width: 100%;
                    font-size: 30px;
                }

                span {
                    color: #970010;

                    @media (min-width: 768px) {
                        display: block;
                    }
                }
            }
        }
    }

}