.hero {
    width: 100%;
    height: auto;
    background-image: url('../img/hero-bg.png');
    background-image: image-set(url("../img/hero-bg.webp") type("image/webp"),
            url("../img/hero-bg.jpg") type("image/jpeg"));
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    overflow: hidden;
    padding-top: 64px;

    &.lazy {
        background-image: none;
    }

    .hero-block {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        height: 100%;
        margin-top: 64px;

        @media (min-width: 1140px) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 64px;
        }

        .hero-text {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 41px;
            /* justify-content: flex-end; 
            margin-top: 64px;*/

            @media (min-width: 992px) {
                max-width: 850px;
                align-self: baseline;
                z-index: 1000;
                padding-left: 45px;
            }

            h2 {
                font-size: 38px;
                font-weight: 600;
                max-width: 365px;
                width: 100%;

                @media (min-width: 768px) {
                    font-size: 60px;
                    max-width: 850px;
                    width: 100%;
                }

                span {
                    color: #DB3544;
                }
            }

            .subtitle {
                font-size: 24px;

                width: 240px;

                margin-bottom: 0;

                @media (min-width: 768px) {
                    margin-bottom: 50px;
                    font-size: 40px;
                    max-width: 700px;
                    width: 100%;
                }

                span {
                    color: #DB3544;
                }
            }

            ul {
                display: grid;
                padding: 0;
                margin: 0;
                max-width: 600px;

                @media (min-width:768px) {
                    display: block;
                }

                li {
                    font-size: 18px;
                    font-weight: 400;
                    display: inline-flex;
                    align-items: center;
                    margin-right: 14px;
                    margin-bottom: 15px;

                    @media (min-width: 768px) {
                        order: 2;
                        font-size: 24px;
                    }

                    &::before {
                        content: "";
                        width: 15px;
                        height: 15px;
                        background-color: #970010;
                        display: block;
                        border-radius: 50%;
                        margin-right: 8px;
                    }
                }
            }

            button {
                width: 100%;
                max-width: 340px;
                height: 70px;
                border-radius: 10px;
                background: #DB3544;
                border: none;

                color: #FFF;

                text-align: center;
                font-size: 20px;
                line-height: 70px;

                padding: 0 15px;

                margin-top: 40px;
                margin-bottom: 34px;

                @media (min-width: 350px) {
                    padding: 0 30px;
                }

                @media (min-width: 768px) {
                    width: 360px;
                    height: 100px;
                    font-size: 24px;
                    line-height: 100px;

                    order: 1;
                    margin-top: 67px;
                    margin-bottom: 84px;
                }

                &:hover {
                    background-color: #970010;
                    box-shadow: 0 4px 10px 0 rgba(0, 26, 24, .15), 0 4px 5px 0 rgba(0, 26, 24, .05);
                }
            }
        }

        picture {
            text-align: center;
            padding-top: 0;
            align-self: auto;
            overflow: visible;
            margin: 0;
            display: flex;

            @media (min-width: 1140px) {
                margin-right: 0;
                align-self: flex-end;
                margin: 0;
                position: absolute;
                right: 0;
                max-height: 100%;
                display: flex;
            }

            .hero-img {
                width: 100%;
                height: auto;
                position: relative;
                right: 0;

                @media (min-width: 768px) {
                    width: auto;
                    bottom: 0;
                    margin-top: 0;
                }
            }
        }
    }
}