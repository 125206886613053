.borrower {

    margin: 100px auto;

    @media (min-width: 992px) {
        margin: 120px auto 150px;
    }

    .borrower-header {
        width: 100%;
        max-width: 1030px;
        margin: auto auto 50px;

        @media (min-width: 992px) {
            margin: auto auto 100px;
        }

        h2 {
            margin-bottom: 0;
        }

        .subtitle {
            margin-top: 30px;
        }
    }

    .borrower-grid {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: 992px) {
            flex-direction: row;
        }

        @media (min-width: 1100px) {
            align-items: stretch;
        }

        .borrower-grid-item {
            position: relative;
            width: 100%;

            @media (min-width: 992px) {
                width: 50%;
            }

            picture {
                width: 100%;
                height: auto;

                @media (min-width: 1100px) {
                    width: auto;
                    position: absolute;
                    left: -25%;
                    top: 20px;
                }

                img {
                    width: 100%;
                    max-width: 350px;
                    height: auto;
                    margin: auto auto 40px; 
                    display: block;

                    @media (min-width: 992px) {
                        max-width: 100%;
                        margin:0; 
                    }

                    @media (min-width: 1200px) {
                        width: auto;
                    }
                }
            }

            ul {
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 25px;
                margin: 0;

                @media (min-width: 992px) {
                    gap: 0;
                }

                li {
                    list-style-type: none;

                    .borrower-icon {
                        width: 80px;
                        height: 80px;
                        background-color: #970010;
                        margin: auto;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            position: static;
                            width: 50px;
                            height: auto;
                        }
                    }


                    p {
                        font-size: 18px;
                        text-align: center;
                        margin-top: 10px;

                        @media (min-width: 992px) {
                            font-size: 24px;
                        }

                        span {
                            color: #970010;
                        }
                    }
                }
            }

            a,
            button {
                max-width: 460px;
                width: 100%;
                height: 70px;
                display: block;
                border-radius: 10px;
                background: #DB3544;
                color: #FFF;
                margin: 50px auto 0;
                text-decoration: none;
                text-align: center;
                line-height: 70px;
                font-size: 20px;
                border: none;

                @media (min-width: 992px) {
                    font-size: 30px;
                    height: 100px;
                    line-height: 100px;
                }

                &:hover {
                    background-color: #970010;
                    box-shadow: 0 4px 10px 0 rgba(0,26,24,.15), 0 4px 5px 0 rgba(0,26,24,.05);
                }
            }
        }
    }
}