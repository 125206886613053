.video-modal {
    display: none;

    &.active {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10000;
        margin: auto;
        background: rgba(0, 0, 0, 0.9);
        display: block;

        .btn-close {
            position: absolute;
            z-index: 1000;
            right: 25px;
            top: 25px;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: rgba(255, 255, 255, 0.1);

            &:hover {
                background-color: rgba(255, 255, 255, 0.5);
            }
        }

        .video-modal-block {
            width: 100vw;
            height: 100vh;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            video {
                width: 100%;
                max-width: 400px;
                height: auto;
            }
        }
    }
}