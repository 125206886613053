.last {
    .last-header {
        width: 100%;
        max-width: 670px;
        margin: auto;

        h2 {
            margin-top: 120px;
        }
    }

    .last-warp {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0 60px;
        flex-direction: column;

        @media (min-width: 992px) {
            flex-direction: row;
        }

        .last-item {
            width: 100%;

            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                position: relative;
                bottom: -30px;
                max-width: 500px;
                width: 100%;
                height: max-content;
                margin: auto;
                display: block;
            }

            a,
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                width: 100%;
                height: 80px;
                line-height: 1.3;
                border-radius: 10px;
                background: #970010;
                color: #FFF;
                text-align: center;
                font-size: 20px;
                border: none;

                @media (min-width: 768px) {
                    max-width: 460px;
                }

                @media (min-width: 992px) {
                    justify-content: center;
                    align-items: center;
                    font-size: 26px;
                    max-width: 460px;
                    height: 100px;
                   /*  padding-left: 30px;
                    padding-right: 30px; */
                }

                &:first-child {
                    @media (min-width: 768px) {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }

                &:last-child {
                    margin-top: 30px;
                    background: #DB3544;
                }
            }
        }
    }
}