.uap-login-form-wrap,
.uap-pass-form-wrap,
.uap-register-form {
    display: block !important;
    margin: 50px auto;


    input {
        border-radius: 10px !important;
        border: 1px solid #970010 !important;
    }

    .uap-submit-form input,
    input[type=submit] {
        width: 100%;
        max-width: 380px;
        height: 64px;
        border-radius: 10px !important;
        background: #970010 !important;
        color: #FFF;
        text-align: center;
        font-size: 16px;
        line-height: 64px;
        display: inline-block;
        text-decoration: none;
        margin-bottom: 25px;

        padding: 0 !important;

        &:hover {
            background: #db3544 !important;
            box-shadow: 0 4px 10px 0 rgba(0, 26, 24, .15), 0 4px 5px 0 rgba(0, 26, 24, .05) !important;
        }

        @media (min-width: 768px) {
            font-size: 20px;
        }
    }

    a {
        &:hover {
            color: #970010 !important;
        }
    }

    .uap-remember-wrapper {
        display: flex;
        align-items: flex-start;

        .uap-form-input-remember {
            height: auto;
            margin: 0 5px 0 0;
            width: auto;
        }
    }

}

.uap-login-template-2 .uap-form-submit input {
    border-radius: 10px !important;
    background: #970010 !important;
}