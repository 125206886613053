@mixin font-face($name, $file, $weight: 400, $style: normal) {
	@font-face {
		font-family: "#{$name}";
		src: local("#{$file}"),
		url('../fonts/#{$file}.woff2') format('woff2'),
		url('../fonts/#{$file}.woff') format('woff');
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
	}
}

@mixin bg ($size:"contain", $position: "center") {
	background-size: #{$size};
	background-position: #{$position};
	background-repeat: no-repeat;
}

@mixin btn_anim($scaleMax:1.05, $scaleMin:0.95) {
	transform-origin: center center;
	transition: all ease-out 240ms;

	&:hover {
		transform: scale(#{$scaleMax});
	}

	&:focus {
		outline: transparent;
	}

	&:focus-visible {
		transform: scale(#{$scaleMax}) translateY(-5%);
	}

	&:active {
		transform: scale(#{$scaleMin});
	}
}

@mixin no-btn ($display:"inline-block") {
	padding: 0;
	margin: 0;
	border: 0;
	background-color: transparent;
	border-radius: 0;
	cursor: pointer;
	appearance: none;
	display: #{$display};
}

@mixin transit ($tr:0.24) {
	transition: all #{$tr}s;
}


@mixin placeholder {
	&::-webkit-input-placeholder { @content; }
	&:-moz-placeholder           { @content; }
	&::-moz-placeholder          { @content; }
	&:-ms-input-placeholder      { @content; }  
}


@mixin adaptiv-font($pcSize, $mobSize, $maxWidth) {
	$addSize: $pcSize - $mobSize;
	$maxWidth: $maxWidth - 320;

	font-size: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth}));
}

@mixin image-set($url) {
	background-image: url("#{$url}@1x.png");
	background-image: image-set(
        url("#{$url}@1x.webp") type("image/webp") 1x,
        url("#{$url}@2x.webp") type("image/webp") 2x,
        url("#{$url}@3x.webp") type("image/webp") 3x,
        url("#{$url}@4x.webp") type("image/webp") 4x,
        url("#{$url}@1x.png") type("image/png") 1x,
        url("#{$url}@2x.png") type("image/png") 2x,
        url("#{$url}@3x.png") type("image/png") 3x,
        url("#{$url}@4x.png") type("image/png") 4x
    );
	background-repeat: no-repeat;
}

@mixin container($max-width:"120rem", $padding:"2rem") {
	width: 100%;
	max-width: #{$max-width};
	padding: 0 #{$padding};
	margin: 0 auto;
}
