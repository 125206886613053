#programs {

    margin-bottom: 120px;

    h2 {
        text-align: center;
        display: block;
        font-size: 30px;
        font-weight: 600;
        margin: 100px auto 80px;

        span {
            color: #970010;
        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: relative;
        overflow: hidden;

        li {
            min-height: 95px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: end;
            background-color: #fff;

            &:nth-child(even) {
                background-color: transparent;
            }

            .container {
                display: flex;

                img {
                    display: none;

                    @media (min-width: 1100px) {
                        display: block;
                        position: absolute;
                        top: 100px;
                    }

                }

                .program-text {
                    margin: 10px auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    max-width: 570px;
                    width: 100%;
                    min-height: 60px;
                    position: relative;

                    flex-wrap: wrap;
                    border-right: 1px solid #970010;
                    padding-right: 15px;


                    @media (min-width: 1100px) {
                        margin: 10px auto 10px 0;
                        flex-wrap: nowrap;
                        padding-right: 0;
                    }

                    &::after {
                        content: "";
                        display: block;
                        width: 18px;
                        height: 20px;
                        background: url("../img/sprite.svg#sprite--arrow-li-right") no-repeat;


                        position: absolute;
                        right: -25px;

                        @media (min-width: 1100px) {
                            position: relative;
                            left: 25px;
                        }
                    }

                    p {
                        text-align: right;
                        font-size: 24px;
                        line-height: 1;
                        max-width: 520px;
                        width: 100%;
                        margin: 0;
                        
                        span {
                            color: #970010;
                            display: inline;
                        }
                    }

                    a {
                        width: 100%;
                        max-width: 232px;
                        height: 64px;
                        line-height: 64px;
                        border-radius: 10px;
                        background: #970010;
                        color: #FFF;

                        text-align: center;
                        font-size: 20px;
                        display: inline-block;
                        padding: 0 40px;
                        text-decoration: none;
                        margin: 10px 0;

                        @media (min-width:1100px) {
                            margin: auto;
                            position: absolute;
                            right: -350px;
                        }

                        &:hover {
                            background: #db3544;
                            box-shadow: 0 4px 10px 0 rgba(0, 26, 24, .15), 0 4px 5px 0 rgba(0, 26, 24, .05);
                        }
                    }
                }


            }
        }
    }

    a {
        width: 100%;
        max-width: 232px;
        height: 64px;
        line-height: 64px;
        border-radius: 10px;
        background: #970010;
        color: #FFF;

        text-align: center;
        font-size: 20px;
        display: block;
        padding: 0 40px;
        text-decoration: none;
        margin: 50px auto;

        &:hover {
            background: #db3544;
            box-shadow: 0 4px 10px 0 rgba(0, 26, 24, .15), 0 4px 5px 0 rgba(0, 26, 24, .05);
        }
    }

    &.about-items {
        margin-top: 80px;
        margin-bottom: 85px;
    }
}