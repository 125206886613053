.menu-mob {
    display: none;

    @media (min-width: 992px) {
        display: none;
    }

    .logo {
        text-decoration: none;
        text-align: center;
        display: inline-block;

        img {
            margin-top: 4px;
        }
    }

    .btn-close {
        display: none;
    }



    .navbar__list {
        list-style-type: none;
        display: block;
        width: 180px;
        padding: 0;
        margin-left: 20px;
        margin-top: 25px;

        li {
            display: block;

            a {
                text-decoration: none;
            }
        }
    }

    .navbar__list__item {
        margin: 0;
        position: relative;
        padding: 0;

        a {
            text-align: left;
            line-height: 1.5;
            color: #000;
            text-decoration: none;
            text-transform: lowercase;
            display: inline-block;
            margin: 5px 0;
            font-size: 14px;

            &:hover {
                opacity: 0.5;
            }

            &::first-letter {
                color: #970010;
            }
        }

        &.active {
            a {
                font-weight: 600;

            }

            &::before {
                @media (min-width: 992px) {
                    content: '';
                    width: 16px;
                    height: 20px;
                    display: block;
                    background-color: #F5F5F5;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: 0 auto;
                }
            }
        }
    }

    &.active {
        display: block;

        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #D1D4D6;
        z-index: 9999;

        display: flex;
        flex-direction: column;
        align-items: center;

        .social-icons {
            display: flex;
            justify-content: end;
            margin: 0 auto;
        }

        .account {
            width: 180px;
            text-align: left;
            margin-left: 20px;
            margin-top: 15px;
            display: block;

            a {
                margin-right: 40px;
                display: flex;
                align-items: end;
                justify-content: left;
                text-decoration: none;
                text-align: left;
                text-transform: lowercase;
                position: relative;
                font-size: 14px;

                &::after {
                    content: "";
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    background: url("../img/sprite.svg#sprite--arrow-li-down") no-repeat;
                    position: absolute;
                    right: 0;
                    top: 4px;
                    margin-right: 20px;
                }

                span {
                    display: inline-block;
                    color: #000;
                    text-align: center;
                    text-transform: lowercase;

                    &::first-letter {
                        color: #970010;
                    }
                }
            }
        }

        .btn-close {
            display: block;
            width: 20px;
            height: 20px;
            padding: 8px;
            cursor: pointer;
            position: absolute;
            top: 5px;
            right: 5px;
        }

        @media (min-width: 992px) {
            display: none;
        }
    }

    .phones {
        margin: 50px auto 20px;

        a {
            color: #970010;
            font-size: 12px;
            display: block;
            margin: 5px auto;
            text-decoration: none;
        }
    }
}