input {
    width: 100%;
    height: 64px;
    padding: 0 16px;
    margin-bottom: 15px;
    margin-top: 15px;
    width: -moz-available;
    width: -webkit-fill-available;
    color: rgba(74, 73, 74, 0.80);
    font-family: 'Exo', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: block;

    border-radius: 10px;
    border: 1px solid #970010;


    &:hover,
    &:focus,
    &:target,
    &:focus-visible,
    &:active {
        border-radius: 10px;
        border: 1px solid #970010 !important;
        background: #FFF;
        box-shadow: none; 
        outline: none;
    }

    &::placeholder {
        @include placeholder {
            color: rgba(74, 73, 74, 0.80);
            font-family: 'Exo', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 64px;
        }
    }

}