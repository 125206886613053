.blog {
    margin-top: 120px;

    .blog-header {
        display: block;
        margin: auto;
        width: 100%;
        max-width: 370px;

        h2 {
            margin: 0 auto;
            display: block;
        }

        h3 {
            font-size: 20px;
            font-weight: 600;
            width: 100%;
            max-width: 824px;
            display: block;
            margin-bottom: 100px;

            @media (min-width: 992px) {
                font-size: 30px;
            }

            span {
                color: #970010;
            }
        }
    }

    .news-company-wrap {
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: 60px;

        margin-bottom: 120px;

        .slick-track {
            display: flex;
            align-items: stretch;
            justify-content: center;
        }

        .news-company-item {
            width: 265px;
            min-height: 240px;
            height: auto;
            border-radius: 15px;
            background: #FFF;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;

            figure {
                position: relative;
                width: 100%;
                max-width: 265px;

                img {
                    width: 100%;
                    height: 200px;
                }

                figcaption {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    margin: 10px 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    p {
                        font-size: 24px;
                        font-weight: 600;
                        color: #FFF;
                        text-align: center;

                        span {
                            background-color: #970010;
                            display: inline-block;
                            padding: 5px 10px;
                            border-radius: 10px;
                            color: #fff;
                        }
                    }
                }
            }

            .news-text {
                margin: 10px 15px;
                display: block;
                height: 100%;
                font-size: 12px;
            }

            .date {
                color: #970010;
                font-size: 12px;
                margin: 10px 15px;
                display: block;
            }

            a {
                color: #970010;
                margin: 10px 15px;
                display: block;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }

                &.readmore {
                    margin: 10px 15px;
                    display: block;
                    text-transform: uppercase;
                    text-decoration: underline;
                }
            }
        }
    }

    .news-useful {

        h3 {
            margin-top: 0;
        }

        .news-useful-warp {
            display: flex;
            align-items: stretch;
            justify-content: center;
            gap: 60px;

            .slick-track {
                margin-bottom: 120px;
            }

            .news-useful-warp-item {
                width: 280px;
                min-height: 240px;
                border-radius: 15px;
                background: #FFF;
                position: relative;
                display: flex;
                overflow: hidden;



                img {
                    width: 100%;
                    display: block;
                    height: auto;
                    margin: 0;
                    padding: 0;
                }

                &::before {
                    content: "";
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    display: block;
                    position: absolute;
                    background: transparent url("../img/sprite.svg#sprite--play") no-repeat;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    cursor: pointer;
                }

                &:hover {
                    &::before {
                        background-color: rgba(255, 255, 255, 0.5);
                    }
                }
            }
        }
    }
}