#reasons_count {
    .reason-head {
        margin: 0 auto 50px;
        display: flex;
        justify-content: center;
        text-align: center;

        @media (min-width: 768px ) {
            text-align: left;
            margin: 80px auto;
        }

        h2 {
            text-align: left;
            display: inline-block;
        }
    }

    .reasons-advantage {
        display: flex;
        flex-direction: column;

        @media (min-width: 992px ) {
            flex-wrap: wrap;
            justify-content: space-around;
            flex-direction: row;
        }

        .reasons-advantage-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin: 0 0 50px;
            @media (min-width: 992px ) {
                width: 50%;
                align-items: baseline;

                &:nth-child(2) {
                    align-items: flex-end;
                }

            }

            .reasons-advantage-count {
                color: #970010;
                font-size: 30px;
                font-weight: 600;
                margin: 0;
                text-align: center;

                span {
                    font-size: 30px;

                    @media (min-width: 768px ) {
                        font-size: 50px;
                    }
                }

                @media (min-width: 992px ) {
                    text-align: left;
                }
            }

            .reasons-advantage-desc {
                font-size: 18px;
                margin: 0;
                text-align: center;

                span {
                    color: #970010;
                }

                @media (min-width: 768px ) {
                    font-size: 24px;
                }

                @media (min-width: 992px ) {
                    text-align: left;
                }
            }
        }
    }
}