#about,
#about-page {
    background-color: #fff;
    width: 100%;
    margin: auto;
    display: flex;

    .about,
    .about-page {
        margin: 50px auto 0;

        @media (min-width: 992px) {
            margin: 100px auto 50px;
        }

        h2 {
            text-align: center;
            font-size: 20px;
            margin: 0 auto 40px;
            font-weight: 600;
            width: 100%;

            @media (min-width: 768px) {
                max-width: 600px;
            }

            @media (min-width: 992px) {
                font-size: 30px;
                max-width: 850px;
            }

            strong {
                color: #970010;
            }
        }

        .about-items {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: 992px) {
                gap: 62px;
                flex-direction: row;
                justify-content: center;
            }

            .about-item-icon {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;

                border-radius: 50%;
                background-color: #970010;

                @media (min-width: 992px) {
                    width: 80px;
                    height: 80px;
                }

                img {
                    width: 50%;
                    height: auto;

                    @media (min-width: 992px) {
                        width: 50px;
                        height: auto;
                    }
                }
            }

            .about-item-text {
                font-size: 18px;
                text-align: center;

                @media (min-width: 992px) {
                    font-size: 24px;
                }

                span {
                    color: #970010;
                }
            }
        }
    }
}

#about-page {
    display: flex;
    flex-direction: column;
    margin-top: 236px;
    background-color: transparent;

    .container {
        @media (min-width: 520px) {
            padding-left: 55px;
            padding-right: 55px;
        }
    }

    h1 {
        font-size: 30px;
        color: #000;
        font-weight: 600;

        span {
            color: #970010;
        }

        @media (min-width: 992px) {
            font-size: 40px;
        }
    }

    .about-text-top {
        p {
            font-size: 20px;
            color: #000;
            font-weight: 600;

            span {
                color: #970010;
            }

            @media (min-width: 992px) {
                font-size: 30px;
            }
        }

        margin-bottom: 25px;

        @media (min-width: 992px) {
            margin-bottom: 50px;
        }
    }

    .about-text-bottom {
        margin-bottom: 50px;

        @media (min-width: 992px) {
            margin-bottom: 100px;
        }

        p {
            color: #000;
            font-weight: 600;
            font-size: 18px;

            span {
                color: #970010;
            }

            @media (min-width: 992px) {
                font-size: 24px;
            }
        }
    }

}