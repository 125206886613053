ol.numeric-decimals {
    list-style: none;
    font-size: 16px;
    padding: 0;
    margin: 10px 0;

    counter-reset: num;

    li {

        font-size: 18px;
        font-weight: 600;
        counter-increment: num;

        &::before {
            content: counter(num);
            margin-right: 15px;
        }

        p {
            margin-left: 25px;
            padding-left: 40px;
            margin: 10px 0;
            font-size: 16px;
            font-weight: 400;
        }

        table {
            margin: 40px;
            font-size: 16px;
            font-weight: 400;
        }
    }

    li {
        margin: 0;
        padding: 0 0 10px 40px;
        position: relative;
        line-height: 20px;

        ol {
            counter-reset: num2;
            list-style: none;

            li {
                counter-increment: num2;
                margin: 10px 0;
                font-size: 16px;
                font-weight: 400;
                
                &::before {
                    content: counter(num) '.' counter(num2);
                    margin-right: 15px;
                }

                p {
                    padding-left: 0;
                }

                ol {
                    counter-reset: num3;
                    list-style: none;

                    li {
                        counter-increment: num3;

                        &::before {
                            content: counter(num) '.' counter(num2) '.' counter(num3);
                            margin-right: 15px;
                        }
                    }
                }

                ul {
                    li {
                        counter-increment: none;
                        padding-left: 0;

                        &::before {
                            content: none;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}