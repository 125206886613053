.plan {
    padding: 0 15px;
    margin-top: 100px;

    @media (min-width:768px) {
        margin-top: 120px;
    }

    .plan-header {
        margin: auto;
        max-width: 1090px;
        width: 100%;

        .subtitle {
            margin-top: 30px;
            margin-bottom: 180px;
        }
    }

    .plan-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        counter-reset: counter 0;
        gap: 100px 0;
        margin-top: 100px;

        li {
            counter-increment: counter 1;
            list-style-type: none;
            width: 100%;
            max-width: 380px;
            border-top: 1px solid #970010;
            position: relative;
            text-align: center;
            padding-top: 20px;
            
            @media (min-width:768px) {
                margin-bottom: 50px;
            }

            .plan-header {
                color: #970010;
                text-align: center;
                font-size: 18px;
                display: inline;

                @media (min-width:768px) {
                    font-size: 24px
                }
            }

            .plan-img {
                width: 270px;
                height: 270px;
                display: block;
                margin: 30px auto;

                @media (min-width:768px) {
                    display: none;
                }
                
            }

            &:first-child {
                .plan-header {
                    border-bottom: 1px solid #970010;
                }
            }

            .plan-text {
                font-size: 18px;
                margin: 0 auto 50px;
                padding: 0 15px;

                @media (min-width:768px) {
                    font-size: 24px
                }
            }

            a,
            button {
                width: 100%;
                max-width: 380px;
                height: 64px;
                border-radius: 10px;
                background: #970010;
                color: #FFF;
                text-align: center;
                font-size: 16px;
                line-height: 64px;
                display: inline-block;
                text-decoration: none;
                margin-bottom: 25px;
                border: none;

                &:hover {
                    background: #db3544;
                    box-shadow: 0 4px 10px 0 rgba(0, 26, 24, .15), 0 4px 5px 0 rgba(0, 26, 24, .05);
                }

                @media (min-width: 768px) {
                    font-size: 20px;
                }
            }

            &::before {
                content: counter(counter);
                color: #C8C8C8;
                text-align: center;
                font-size: 50px;
                font-weight: 600;
                line-height: 1.5;
                display: block;
                left: 0;
                right: 0;
                top: -90px;
                margin: auto;
                position: absolute;

                @media (min-width: 768px) {
                    font-size: 80px;
                    top: -120px;
                }
            }

            &::after {
                content: "";
                display: block;
                width: 20px;
                height: 18px;
                background: url(../img/sprite.svg#sprite--arrow-li-right) no-repeat;
                position: absolute;
                left: 0;
                right: 0;
                top: -10px;
                margin: auto;
            }
        }

        &::after {
            
            width: 270px;
            height: 270px;
            background: url("../img/plan.svg") no-repeat;
            display: block;
            position: absolute;
            top: calc(50% - 135px);
            right: -270px;

            @media (min-width: 1100px) {
                content: '';
            }
        }
    }
}