#map-img {
    background-color: #fff;
    overflow: hidden;
    
    h2 {
        text-align: center;
        margin: 20px auto 50px;
        font-size: 20px;

        width: 100%;

        max-width: 262px;

        @media (min-width: 992px) {
            max-width: 630px;
            margin: 100px auto 50px;
            font-size: 30px;
        }
    }

    .map-img-block {
        display: block;
        position: relative;

        picture {
            margin: 0 auto 53px;
            padding: 0;
    
            width: 100%;
    
            img {
                margin: auto;
                display: block;
                max-width: 1100px;
                width: 100%;
                height: auto;
            }
    
            &.picture-logo {
                width: 50%;
                max-width: 524px;
                height: auto;
                position: absolute;
                top: calc(50% - 50px);
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                padding: 0;
                display: flex;

                @media (min-width: 768px) {
                    width: 100%;
                    top: calc(50% - 90px);
                }

                img {
                    margin: auto;
                }
            }
        }
    }

    
}