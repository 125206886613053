#contacts {
    position: relative;
    overflow: hidden;

    .contacts {
        
        margin: auto;
        z-index: 10;
        position: relative;
        top: 5px;
        width: 100%;
        min-height: 375px;
        border-radius: 15px;
        background: #FFF;
        display: table;

        @media (min-width: 992px) {
            position: absolute;
            width: 500px;
            left: inherit;
            right: 15%;
            top: 0;
            bottom: 0;
        }

        @media (min-width: 1200px) {
            left: 50%;
            right: inherit;
        }

        .contacts-wrap {
            width: 100%;
            border-radius: 15px;
            background: #FFF;
            @media (min-width: 460px) {
                width: 500px;
                margin: auto;
            }
        }

        h2 {
            color: #970010;
            font-size: 24px;
            font-weight: 600;
            margin: 35px auto 0;
            text-align: center;
            
            @media (min-width: 992px) {
                font-size: 40px;
            }
        }

        .contacts-list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex-direction: column;

            gap: 20px;
            margin: 50px 40px;

            @media (min-width: 410px) {
                flex-direction: row;
                align-items: stretch;
                justify-content: space-between;
            }

            .contacts-list-item {
                width: 100%;

                @media (min-width: 410px) {
                    width: calc(50% - 10px);
                    min-width: 70px;
                }
                
                display: flex;
                flex-direction: column;

                span {
                    display: block;
                    font-size: 16px;

                    &.head {
                        font-size: 16px;
                        color: #970010;
                    }
                }

                a {
                    font-size: 16px;
                    color: #000;
                    text-decoration: none;

                    &.email-link {
                        text-decoration: underline;
                    }
                }


            }
        }
    }
}