.social-icons {
    width: 164px;
    margin: auto;
    
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: relative;
        overflow: hidden;

        display: flex;
        gap: 8px;
        align-items: center;

        li {

            a {
                width: 30px;
                height: 30px;

                padding: 0;

                img {
                    width: 25px;
                    height: 25px;

                    margin: 0;
                }

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
} 