#partners {

    overflow: hidden;

    .partners-head {
        width: 100%;
        margin: 0 auto;
        text-align: left;

        @media (min-width: 768px) {
            margin: 20px auto 80px;
            max-width: 960px;
            text-align: left;
        }

        .h2 {
            font-size: 24px;
            font-weight: 600;
            margin: 20px auto 30px;

            @media (min-width: 768px) {
                font-size: 40px;
            }

            span {
                color: #970010;
            }
        }

        .subtitle {
            font-size: 20px;

            @media (min-width: 768px) {
                font-size: 30px;
            }
        }
    }

    .partners {

        display: inline-block;
        margin: 28px auto;

        .js-marquee {
            display: flex;
            gap: 28px 20px;
        }

        .partners-item {
            width: 280px;
            height: 160px;
            border-radius: 15px;
            border: 1px solid rgba(0, 0, 0, 0.20);
            margin: auto;
            display: grid;
            align-items: center;
            justify-content: center;
        }
    }
}

#partners-page {
    margin-top: 236px;
    margin-bottom: 100px;

    &.container {
        @media (min-width: 520px) {
            padding-left: 55px;
            padding-right: 55px;
        }
    }

    .partners-item {
        margin-bottom: 50px;
    }

    h1 {
        font-size: 30px;
        color: #000;
        font-weight: 600;

        margin: 30px auto;
        display: block;

        text-align: center;

        span {
            color: #970010;
        }

        @media (min-width: 992px) {
            font-size: 40px;
        }
    }

    h2 {
        color: #970010;
        font-size: 24px;
        font-weight: 600;

        @media (min-width: 992px) {
            font-size: 30px;
        }
    }

    p {
        color: #000;
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 15px;

        @media (min-width: 992px) {
            font-size: 24px;
        }

        span {
            color: #970010;
        }

        &.company-type {
            color: #000;
            font-size: 24px;
            font-weight: 600;

            @media (min-width: 992px) {
                font-size: 30px;
            }
        }
    }
}