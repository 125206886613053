.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow: hidden;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.9);
}

.modal-dialog {
    position: relative;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    outline: 0;
    font-size: 24px;
    margin: 15px 0 50px;

    span {
        color: #970010;
    }

}

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 15px 40px;
}

.modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
    margin: 0.25rem;
}


@media (min-width: 576px) {
    .modal-dialog {
        width: 100%;
        max-width: 440px;
        height: auto;
        min-height: 420px;
        margin: 1.75rem auto;
    }
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: .75;
}

.modal button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}

.modal form input {
    height: 64px;
    line-height: 64px;
}

.modal form input[type=submit],
.modal form button {
    border-radius: 10px;
    background: #970010;
    width: 232px;
    height: 64px;
    color: #FFF;

    text-align: center;
    line-height: 64px;
    font-size: 20px;
    display: block;
    margin: 30px auto 16px;
    border: none;
    outline: 0;
    color: #FFF;
    text-align: center;
    font-family: 'Exo', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.modal p {
    margin-bottom: 50px;
    font-size: 24px;
    span {
        color: #970010;
    }
}

.modal p.privacy {
    color: rgba(74, 73, 74, 0.80);
    text-align: center;
    font-size: 12px;
    display: block;
    width: 260px;
    margin: 15px auto;

    a {
        font-size: 12px;
        color: rgba(151, 0, 16, 0.80);
        text-decoration: underline;
    }
}

.modal a.close.disabled {
    pointer-events: none;
}