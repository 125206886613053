.hypothec {
    margin-bottom: 120px;

    position: relative;

    .hypothec-bg {

        @media (min-width: 1800px) {
            position: absolute;
        }

        width: 100%;
        max-width: 615px;
        height: auto;
        margin: auto;
        display: block;
        bottom: 15px;
        right: -24%;

        &.hypothec-bg-left {
            right: inherit !important;
            left: -150px
        }
    }

    .hypothec-header {
        display: block;
        margin: auto;
        width: 100%;
        max-width: 1030px;

        .subtitle {
            width: 100%;
            max-width: 824px;
            display: block;
            /* margin-bottom: 100px; */
        }
    }

    .hypothec-form-item-text {

        @media (min-width: 810px) {
            display: none;
        }

        p {
            font-size: 24px;

            span {
                color: #970010;
            }
        }

        ul {
            li {
                font-size: 24px;

                span {
                    color: #970010;
                }
            }
        }
    }

    .hypothec-form {
        width: 100%;
        max-width: 894px;
        border-radius: 15px;
        background: #FFF;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        margin: auto;

        @media (min-width: 1800px) {
            margin-top: 100px;
        }

        @media (min-width: 1800px) {
            margin-left: 0;
        }

        .hypothec-form-head {
            color: #970010;
            font-size: 24px;
            display: block;
            margin: 50px auto;
            width: 100%;
            display: flex;
            justify-content: end;
            text-align: center;

            p {
                width: 100%;
                margin: auto;

                @media (min-width: 810px) {
                    width: 50%;
                    margin: 0;
                }
            }


        }

        .hypothec-form-item {
            width: 100%;
            max-width: 350px;
            padding: 0 15px;

            @media (min-width: 810px) {
                padding: 0;
            }

            .hypothec-form-item-text {
                display: none;

                @media (min-width: 810px) {
                    display: block;
                }
            }

            .hypothec-form-btn {
                width: 232px;
                height: 64px;
                border-radius: 10px;
                background: #970010;
                color: #FFF;
                text-align: center;
                font-size: 20px;
                line-height: 64px;
                border: none;
                margin: 30px auto 16px;
                display: block;

                &:hover {
                    background: #db3544;
                    box-shadow: 0 4px 10px 0 rgba(0, 26, 24, .15), 0 4px 5px 0 rgba(0, 26, 24, .05);
                }
            }

            .hypothec-form-policy {
                color: rgba(74, 73, 74, 0.80);
                text-align: center;
                font-size: 12px;
                width: 260px;
                display: block;
                margin: 0 auto 50px;

                a {
                    color: rgba(151, 0, 16, 0.80);
                    font-family: Exo;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%;
                    text-decoration-line: underline;
                    text-decoration: underline;
                }
            }

        }
    }
}